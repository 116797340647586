
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands, getMediaType } from "@/utils/common";
import { isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";
import QuestionTemp from "./components/question-temp.vue";
import dayjs from "dayjs";
const learning = namespace("learning");
const app = namespace("app");
@Component({
  components: { FileUpload, QuestionTemp }
})
export default class TestItemEdit extends Vue {
  @learning.Action queryAnswerSheetDetail;
  @learning.Action submitScoring;
  emptyToLine = emptyToLine;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  splitThousands = splitThousands;
  dayjs = dayjs;
  getMediaType = getMediaType;
  showFilePreview: boolean = false;
  dataLoaded: boolean = false;
  curFileUrl: string = "";
  anwserSheetData: any = {};
  showScoringTips: boolean = false;
  get breadData() {
    return [
      { name: t("v210831.marking-and-scoring"), path: "/learning/exam/score" },
      { name: this.isView ? t("v210831.score-details") : t("v210831.marking") }
    ];
  }
  get answerSheetCode() {
    return this.$route.params.answerCode;
  }
  created() {
    this.init();
  }
  groupIndex: number = 1;
  get isView() {
    return this.$route.name === "scoreView";
  }
  init() {
    this.queryAnswerSheetDetail({
      answerSheetCode: this.answerSheetCode
    }).then(data => {
      this.anwserSheetData = data.data;
      this.dataLoaded = true;
    });
  }
  handlePreviewFile(url) {
    this.showFilePreview = true;
    this.curFileUrl = url;
  }
  submitScore() {
    this.btnStartLoading();
    this.submitScoring({
      answerSheetCode: this.answerSheetCode,
      examPaperBean: this.anwserSheetData.examPaperBean
    })
      .then(data => {
        this.$message.success(t("v210831.successful-marking"));
        this.$router.replace(`/learning/exam/scoro-view/${this.answerSheetCode}`);
      })
      .finally(data => {
        this.btnStopLoading();
      });
  }
  shortAnswerTotal: number = 0;
  totalSroce: number = 0;
  checkData() {
    let questionList = this.anwserSheetData.examPaperBean.examPaperQuestionList || [];
    let shortAnswerTotal = 0;
    for (let i = 0; i < questionList.length; i++) {
      let question = questionList[i];
      if (question.questionType === "3") {
        shortAnswerTotal += Number(question.questionGrades || 0);
        if (!isPositiveIntegerZero(question.questionGrades)) {
          this.$message.error(t("v210831.the-score-of-integer"));
          return false;
        }
        if (Number(question.questionGrades) > Number(question.questionScore)) {
          this.$message.error(t("v210831.the-score-cannot"));
          return false;
        }
      }
    }
    this.shortAnswerTotal = shortAnswerTotal;
    this.totalSroce = Number(this.anwserSheetData.totalGrades) + shortAnswerTotal;
    return true;
  }
  handleShowScoringTips() {
    if (this.checkData()) {
      this.showScoringTips = true;
    }
  }
}
